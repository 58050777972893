@import "../../../baseTheme/settings";


.craftProduct-home {
    a {
        display: block;

        .explore-btn {
            position: absolute;
            left: 50%;
            bottom: 30px;
            transform: translateX(-50%);
            background-color: $plum;
            color: #fff;
            border-radius: 100px;
            height: 37px;
            padding: 0 24px;
            font-size: 14px;
            letter-spacing: -0.28px;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            transition: all ease 0.2s;

            @include media-breakpoint-down(md) {
                bottom: -90px;
                opacity: 1;
            }
        }

        picture {
            border-radius: 12px;
            overflow: hidden;
            margin-bottom: 24px;
            display: block;
            position: relative;

            img {
                width: 100%;
            }
        }

        .craft-name {
            text-align: center;
            @include primaryFont(400);

            @include media-breakpoint-down(md) {
                font-size: 20px;
                letter-spacing: -0.8px; 
            }
        }

        &:hover {
            .explore-btn {
                opacity: 1;
            }
        }
    }
}